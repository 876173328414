<template>
  <div :id="myEditorId"></div>
</template>

<script>
import E from "wangeditor";

export default {
  name: "MyEditor",
  props: {
    editorTxt: String,
    editorId: String,
    editorDisabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 500,
    },
    excludeMenus: Array,
    fileRule: {
      type: String,
      default: 'RICH_TEXT_IMG',
    },
    autoSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorContent: "",
      myEditorId: "myEditor",
      editor: {},
    };
  },
  watch: {
    editorTxt(val) {
      // 设置内容
      this.editor.txt.html(val);
    }
  },
  created() {
    if (this.editorId) {
      this.myEditorId = this.editorId;
    }
  },
  mounted() {
    this.editor = new E(`#${this.myEditorId}`);
    this.editor.config.pasteFilterStyle = false;
    this.editor.config.height = this.height;
    // 图片上传配置 server 接口地址
    this.editor.config.uploadImgServer = "/api/common/qiniu/uploadFile";
    // 上传接口等待的最大时间，30 秒钟
    this.editor.config.uploadImgTimeout = 30 * 1000;
    // 限制图片大小是 5M
    this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    // 一次最多上传 100 个图片
    this.editor.config.uploadImgMaxLength = 5;

    this.editor.config.uploadFileName = "file";
    this.editor.config.zIndex = 10;

    // 视频上传配置 server 接口地址
    this.editor.config.uploadVideoServer = '/upload/file';
    // 自定义视频 fileName
    this.editor.config.uploadVideoName = 'file';
    // 上传接口等待的最大时间，10分钟
    this.editor.config.uploadVideoTimeout = 1000 * 60 * 10;
    // 默认限制视频大小是 600MB，这个地方不知道是不是源代码bug
    // 源代码node_modules\wangeditor\src\menus\video\upload-video.ts 54行里面当成KB处理了
    // 和图片的配置不一样，升级wangeditor的时候注意测试这个
    this.editor.config.uploadVideoMaxSize = 600 * 1024;
    // // 限制类型
    this.editor.config.uploadVideoAccept = ['mkv','avi','mov','mpeg','mpg','rm','mov','asf','wmv','3gp','flv','mp4','m3u8','ts','webm','mxf','rmvb','mp3','WAV','m4a','aac']
    // 使用 editor.config.excludeMenus 当只需剔除少数菜单的时候，直接设置不需要的菜单
    if (this.excludeMenus && this.excludeMenus.length > 0) {
      this.editor.config.excludeMenus = this.excludeMenus;
    }

    // 配置 onchange 回调函数
    const that = this;
    
    this.editor.config.onchange = function (newHtml) {
      that.editorContent = newHtml;
      if (that.autoSave === true) {
        that.$emit('auto-save');
      }
    };
    if (this.autoSave === true) {
      this.editor.config.onchangeTimeout = 1000;
    }

    // 创建富文本
    this.editor.create();

    // 设置内容
    this.editor.txt.html(this.editorTxt);

    this.editor.config.uploadImgHooks = {
      // 图片上传并返回了结果，想要自己把图片插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
      customInsert(insertImgFn, result) {
        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(result.data.all_path_url);
      },
    };

    // 视频上传回调
    this.editor.config.uploadVideoHooks = {
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert(insertVideoFn, result) {
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.data.imgUrl)
      }
    };

    this.editor.config.uploadImgParams = {
     
    };
    this.editor.config.uploadVideoParams = {
     
    };
    if (this.editorDisabled) {
      this.editor.disable();
    };
  },
};
</script>