<template>
  <el-dialog
    top="2vh"
    append-to-body
    width="800px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='title' label='新闻标题'>
        <el-input v-model="form.data.title" placeholder="请输入" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='image' label='图片'>
        <el-upload
          class="avatar-uploader"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest"
          accept="image/jpeg, image/png,   image/gif">
          <img v-if="form.data.image" :src="form.data.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item prop='content' label='内容'>
        <my-editor ref="editor" :height="300" :editorTxt="this.form.data.content"></my-editor>
      </el-form-item>
      <el-form-item prop='top' label='是否置顶'>
        <el-radio-group v-model="form.data.top">
          <el-radio :label="1">置顶</el-radio>
          <el-radio :label="0">不置顶</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img v-if="dialogVisible" width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>
</template>

<script>
  import MyEditor from "@/components/MyEditor.vue";
  import common from '@/util'
  export default {
    name: 'NewsEdit',
    components: {
      MyEditor,
    },
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            title: '',
            image: '',
            content: '',
            top: 0
          },
          rules: {
            title: [{ required: true, message:'请输入标题', trigger: 'change' }],
            image: [{ required: true, message: '请上传图片', trigger: 'change' }],
          }
        },
        dialogVisible: false,
        dialogImageUrl: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        this.$nextTick(() => {
          common.deepClone(this.form.data, row)
          this.form.data.top = row.top > 0 ? 1 : 0;
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.data.content = this.$refs.editor.editorContent;
            if (!this.form.data.content) {
              this.$message.warning("内容不能为空");
              return;
            }
            let apiUrl = !this.isChange ? '/government/news/add' : '/government/news/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      }
    }
  }
</script>
